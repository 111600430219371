<!-- 登录 -->
<template>
    <div class="login">
        <img src="../../assets/logo.png" class="logo" />
        <!-- 登录框 -->
        <div class="c-center">
            <!-- 左边 -->
            <div class="c-center-left">
                <p class="welcome">欢 迎 来 到</p>
                <p style="padding-top: 20px;">深圳書畫藝術學院后台管理系统</p>
            </div>
            <!-- 右边 -->
            <div class="c-center-right">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form">
                    <el-form-item label="" prop="userName">
                        <span class="user"></span>
                        <el-input placeholder="请输入用户名" v-model="ruleForm.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="userPwd">
                        <span class="pwd"></span>
                        <el-input placeholder="请输入密码" show-password v-model="ruleForm.userPwd"
                            @keyup.enter.native="submitForm('ruleForm')">
                        </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="" prop="proving">
                        <el-input  placeholder="请输入验证码" class="proving"  v-model="ruleForm.proving"></el-input>
                        <div class="yanz" >
                            <div class="regimg" >
                                <img :src="ruleForm.identifycode" style="width: 100%;height: 100%;">
                            </div>
                            <i class="el-icon-refresh-right"></i>
                        </div>
                    </el-form-item> -->
                    <el-form-item label="">
                        <button type="button" @click="submitForm('ruleForm')" class="login-btn">登 录</button>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="checked">
                            <el-checkbox v-model="ruleForm.checked">记住用户名</el-checkbox>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import Base64 from '../../utils/Base64.js'
    export default {
        data() {
            return {
                ruleForm: {
                    userName: "", //用户名
                    userPwd: "", //密码
                    proving: "", //验证码
                    identifycode: "", //验证码图片
                    checked: false, //记住用户名多选框
                },
                rules: {
                    userName: [{
                        required: true,
                        message: '用户名不能为空',
                        trigger: 'blur'
                    }, ],
                    userPwd: [{
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    }, ],
                    // proving:[
                    //     { required: true, message: '验证码不能为空', trigger: 'blur' },
                    // ] ,
                }
            }
        },
        created() {
            // 解密账号密码
            let username = this.$cookies.get("userName")
            let userpwd = this.$cookies.get("userPwd")
            // console.log(username, userpwd)
            if (username && userpwd) {
                this.ruleForm.userName = Base64.decode(username)
            	this.ruleForm.userPwd = Base64.decode(userpwd)
                this.ruleForm.checked = true
            }
        },
        methods: {
            submitForm(form) { //登录
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        this.login();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            login() {
                let tmpe = {
                    account: this.ruleForm.userName,
                    password: this.ruleForm.userPwd
                }
                this.$request({
                    method: 'post',
                    url: '/api/user/login',
                    data: tmpe
                }).then(res => {
                    console.log('res', res);
                    this.$message({
                        message: res.msg,
                        type: res.code == 1 ? 'success' : 'error'
                    });
                    if (res.code == 1) {
                        let data = res.data;
                        console.log(data,"登录信息");
                        this.$cookies.set('token', data.userinfo.token);
                        localStorage.setItem("userinfo", JSON.stringify(data.userinfo)) 
                        this.setUserInfo()
                        this.$router.push({
                            path: '/dashboard'
                        });
                    }

                }).catch(err => {
                    console.log('err', err);
                })
            },
            setUserInfo(){
                if(this.ruleForm.checked){
                    this.$cookies.set("userName",Base64.encode(this.ruleForm.userName))
                    this.$cookies.set("userPwd",Base64.encode(this.ruleForm.userPwd))
                    this.$cookies.set("checked",this.ruleForm.checked)
                }else{
                    this.$cookies.remove('userName')
                    this.$cookies.remove('userPwd')
                    this.$cookies.remove('checked')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .login {
        width: 100%;
        height: 100vh;
        background: url(../../assets/beijing.png)center top no-repeat;
        background-size: cover;
        position: relative;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
      	 // align-items: center;
    }

    .logo {
        position: absolute;
        top: 77px;
        left: 116px;
    }

    // 登录框
    .c-center {
        width: 872px;
        height: 438px;
        // border: 1px solid #000000;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -219px;
        margin-left: -436px;
        display: flex;
    }

    // 登录框左边
    .c-center-left {
        width: 428px;
        height: 438px;
        background: rgba(255, 255, 255, 0.38);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    p {
        width: 336px;
        height: 31px;
        font-size: 20px;
        color: #29726C;
    }

    .welcome {
        font-size: 24px;
        border-bottom: 1px solid #29726C;
        padding-bottom: 20px;
        background: url(../../assets/tubiao.png) 130px 4px no-repeat;
    }

    //登录框右边
    .c-center-right {
        width: 444px;
        height: 438px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .el-input {
            width: 309px;
            height: 45px !important;
        }
    }

    .form {
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-wrap: wrap;
    }

    ::v-deep .el-input__inner {
        border-radius: 0px !important;
        vertical-align: middle;
    }

    ::v-deep .proving {
        width: 175px !important;
    }

    // 用户名
    .user {
        display: inline-block;
        width: 49px;
        height: 38px;
        border: 1px solid #CCCCCC;
        border-right: none;
        background: url(../../assets/user.png) 12px 5px no-repeat #F3F3F3;
        vertical-align: middle;
    }

    //用户密码
    .pwd {
        display: inline-block;
        width: 49px;
        height: 38px;
        border: 1px solid #CCCCCC;
        border-right: none;
        background: url(../../assets/mima.png) 12px 5px no-repeat #F3F3F3;
        vertical-align: middle;
    }

    // 验证码图片容器
    .regimg {
        display: inline-block;
        width: 140px;
        // border: 1px solid #CCCCCC;
        height: 38px;
        vertical-align: middle;
        margin: 0px 5px;
    }

    .yanz {
        display: inline-block;
        width: 185px;
        height: 40px;
    }

    //刷新图标
    .el-icon-refresh-right {
        font-weight: bold;
        font-size: 28px;
        vertical-align: middle;
        color: #333;
        cursor: pointer;
    }

    //登录按钮
    .login-btn {
        display: inline-block;
        width: 309px;
        height: 47px;
        background: #29726C;
        color: #fff;
        font-size: 20px;
        border: 1px solid #ccc;
        cursor: pointer;
        margin: 0px 25px;
        outline: none;
    }

    .el-checkbox {
        color: #333;
        font-weight: bold;
    }

    ::v-deep .el-checkbox__inner:hover {
        border-color: #ccc;

    }

    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #333;
        font-size: 14px;
    }

    ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #ccc;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        color: #333;
        background-color: #29726C;
        border-color: #ccc;
    }
</style>
